/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/

/* Global Reset */
* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: medium;
}

body {
  color: #333;
  background-color: #ececec;
  font: 13.34px 'Roboto', 'Microsoft JhengHei', Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-size: 1rem;
  hyphens: auto;
  line-height: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Comfortaa', 'Microsoft JhengHei', sans-serif;
  color: #4d4d4d;
  padding: 0.2em 0;
  line-height: 1.1em;
}

h1 {
  font-size: 1.8em;
  font-size: 1.8rem;
  margin-top: 1.2em;
}

h2 {
  font-size: 1.4em;
  font-size: 1.4rem;
}

h3 {
  font-size: 1.25em;
  font-size: 1.25rem;
}

p {
  text-shadow: #fff 1px 1px 1px;
  margin: 1em 0;
  color: #666;
}

a         { color: #1794D1; }
a:hover   { color: darken(#1794D1, 10%); }
a:visited { color: darken(#1794D1, 20%); }


/*****************************************************************************/
/*
/* Site
/*
/*****************************************************************************/

header {
  nav {
    background-color: #000000;
    border-bottom: 5px solid #08c !important;
    font-family: 'Roboto Condensed', sans-serif;

    a {
      font-weight: bold;
      text-decoration: none;

      &.site-title {
        color: #1793d1 !important;

        &:hover {
          color: lighten(#1793d1, 10%) !important;
        }
      }
    }

    a.nav-link {
      padding-left: 0.5em !important;
      padding-right: 0.5em !important;
    }
    li.active a.nav-link {
      background-color: #555 !important;
      border-radius: 8px;
    }
  }
}


section.content {
  padding-top: 2.5em;
  background: linear-gradient(#D8E7F0, #eeeeee, #ececec);

  ul {
    margin-left: 1.2em;
    li {
      margin-left: 0.5em;
      color: #666;
      margin-bottom: 0.8em;
    }
  }

  #home {
    img.logo {
      display: block;
      margin: 0 auto;
    }

    i.fas {
      margin-right: 0.2em;
    }

    h2 {
      text-shadow: #999 0px 2px 2px;
      font-size: 1.4em;
      font-size: 1.4rem;
      line-height: 1.5em;
      letter-spacing: 1px;

      &.slogan {
        text-shadow: #999 2px 2px 2px;
        font-size: 1.8em;
        font-size: 1.8rem;
        letter-spacing: 1px;
        text-align: center;
        padding-top: 0.8em;
        font-weight: normal;
      }
    }

    div.briefing {
      margin: 1em auto;
    }

    div.highlights {
      div.character {
        background: none;
      }
    }

    div.posts {
      ul {
        display: table;
        margin: 0;
        border-collapse: separate;

        li {
          display: table-row;
          list-style: none;

          div.post__date {
            display: table-cell;
            font-family: 'Roboto Condensed';
            text-align: center;
            white-space: nowrap;

            span {
              display: block;
              padding: 0 0.2em;
              line-height: 1em;
              color: #ececec;
              background-color: #1793d1;
              border-top: 1px solid darken(#1793d1, 20%);
              border-left: 1px solid darken(#1793d1, 20%);
              border-radius: 4px;
              text-shadow: darken(#1793d1, 20%) 1px 1px 0px;
            }
          }

          div.post__title {
            display: table-cell;
            padding-left: 0.3em;
            padding-bottom: 0.5em;
            span {
            }
          }
        }
      }
    }
  }
}

.meta {
  color: #aaa;
}

footer {
  font-size: 0.9em;
  font-size: 0.9rem;
  color: #666;
  border-top: 4px solid #7FBDDB;
  margin-top: 2em;
  margin-bottom: 1em;
  padding-top: 0.6em;
  overflow: hidden;

  h1 {
    color: #666;
    font-size: 1.2em;
    margin: 0;
  }

  p {
    margin: 0;
    color: lighten(#666, 10%);
  }

  .contact {
    float: left;
    margin-right: 3em;
  }

  .rss {
    margin-top: 1.1em;
    margin-right: -.2em;
    float: right;

    img {
      border: 0;
    }
  }
}


/*****************************************************************************/
/*
/* Posts
/*
/*****************************************************************************/

.post {
  /* standard */
  pre {
    border: 1px solid #ddd;
    background-color: #eef;
    padding: 0 .4em;

    code {
      border: none;
    }

    /* terminal */
    &.terminal {
      border: 1px solid #000;
      background-color: #333;
      color: #FFF;
    }

    &.terminal code {
      background-color: #333;
    }
  }

  ul, ol {
    margin-left: 1.35em;
  }

  code {
    border: 1px solid #ddd;
    background-color: #eef;
    padding: 0 .2em;
  }
}
